import { GsConnectPostItem, InternalTitlePermissionListItem, SingleMenuGroup } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Breadcrumb } from './../utilities/internal-share/models/model';
import { OutdatedBuildInfoModel, MenuGroupModel, MenuItemModel, NotificationReceiveInfoModel } from './models';

export interface NavItem {
	displayName: string;
	disabled?: boolean;
	iconName?: string;
	route?: string;
	children?: NavItem[];
	expanded?: boolean;
	customName?: string;
}

export interface PipelineLead {
	pipelineLeadId: string;
	leadType: string;
	leadName: string;
	phone: string;
	email: string;
	lastContact: Date;
	leadStatus: string;
	loanOfficer: string;
	leadNotes: string;
}

export interface DashboardWidgetConfig {
	title: string;
	componentName: string;
}

export interface CustomDashboardLayout {
	name: string;
	layout: any;
}

export interface LoginState {
	isLoggedIn: boolean;
	userId: string;
}

export interface MenuLoadedState {
	menuLoaded: boolean | undefined;
}

export interface PermissionLoadedAction {
	permissionList: InternalTitlePermissionListItem[] | undefined;
}

export interface MenuGroupUpdatedAction {
	menuGroups: MenuGroupModel[];
}

export interface RecentMenuUpdatedAction {
	menuList: MenuItemModel[];
}

export interface FavoriteMenuUpdatedAction {
	menuList: MenuItemModel[];
}

export interface WikiModuleStateUpdatedAction {
	isWikiModuleDataUpdated: boolean;
}

export interface ScreenSizeInfo {
	isChanged: boolean;
	screenSize: ScreenSize;
}

export interface ScreenSizeState {
	screenSizeInfo: ScreenSizeInfo;
}

export interface NotificationListModifiedState {
	notifications: NotificationReceiveInfoModel[];
}

export interface NotificationAddedState {
	notification: NotificationReceiveInfoModel;
}

export interface ChatMessageListModifiedState {
	chatMessages: ChatMessage[];
}

export interface ChatMessageAddedState {
	chatMessage: ChatMessage;
}

export interface NavbarTitleUpdatedState {
	title: string;
}

export interface ApplyMarginOnBodyState {
	applyMargin: boolean;
}

export interface BreadcrumbUpdatedAction {
	breadcrumb: Breadcrumb[];
}

export interface BreadcrumbFavoriteMenuAction {
	breadcrumb: Breadcrumb[];
}

export interface OutdatedBuildAction {
	buildIsOutdated: boolean;
}

export enum ScreenSize {
	Small = 'Small',
	Large = 'Large',
	XL = 'XL',
	XXL = 'XXL',
	Mobile = 'Mobile',
}

export enum UserInfoType {
	User = 'User',

	UserGroup = 'UserGroup',

	NotificationTopic = 'NotificationTopic',
}

export enum PostActionTypeEnum {
	POST_ADDED = 'POST_ADDED',
	POST_DELETED = 'POST_DELETED',
	POST_UPDATED = 'POST_UPDATED',
	COMMENT_ADDED = 'COMMENT_ADDED',
	NESTED_COMMENT_ADDED = 'NESTED_COMMENT_ADDED',
	COMMENT_UPDATED = 'COMMENT_UPDATED',
	COMMENT_DELETED = 'COMMENT_DELETED',
	CALENDAR_EVENT_UPDATED = 'CALENDAR_EVENT_UPDATED',
	COMMENT_VIEW_LESS = 'COMMENT_VIEW_LESS',
	POST_REACTION = 'POST_REACTION',
	OPEN_POST_MODAL = 'OPEN_POST_MODAL',
	HIDE_COMMENT_SECTION = 'HIDE_COMMENT_SECTION',
	TOGGLE_COMMENT_SECTION = 'TOGGLE_COMMENT_SECTION',
	SHOW_POST_MODAL_CONTENT = 'SHOW_POST_MODAL_CONTENT',
}

export interface UserAndGroupInfoModel {
	notificationTopicSubscriber?: string | null;
	identifierGUID: string | null;
	name: string | null;
	description: string | null;
	type: UserInfoType | null;
}

export interface ChatMessage {
	chatHistoryGUID?: string;
	fromUserGUID: string;
	fromUserName: string;
	toUserGUID: string;
	type: string;
	messageBody: string;
	mode: string;
	readYN: string;
	messageSendTime: string;
}

export interface BreadcrumbFavoriteIconToggledState {
	showFavoriteIconOnBreadcrumb: boolean;
}

export interface BreadcrumbIconsToggleActionState {
	showEditIconOnBreadcrumb?: boolean;
	showEyeIconOnBreadcrumb?: boolean;
}

export interface DirtySaveActionState {
	hasUnSavedChanges?: boolean;
}

export interface ConfirmationPopUpModel {
	title: string;
	message: string;
	showCloseButton?: boolean;
	hideOnOutsideClick?: boolean;
	onConfirm: () => void;
	onCancel?: () => void;
}
export interface SuggestionUpdateActionState {
	suggestionUpdated?: boolean;
}
export interface UserProfileUpdateActionState {
	userProfileUpdated?: boolean;
}

export interface MenuGroupSortOrderUpdatedActionState {
	menuGroupSortOrderUpdated: SingleMenuGroup[];
}

export interface OutdatedBuildActionState {
	apiBuildMinorVersion: OutdatedBuildInfoModel;
}

export interface PostActionState {
	actionType: PostActionTypeEnum;
	postItem?: GsConnectPostItem;
	index?: number;
	commentLoaded?: boolean;
	imageLoaded?: boolean;
}
